<template>
	<div>
		<div class="border bg-light p-3 my-2" v-for="(condition, index) in model">
			<div class="row d-flex align-items-center">
				<div class="col">
					<trigger :config="config" :disabled="disabled" class="my-2" v-model="model[index]"
							 :key="index"></trigger>
				</div>
				<div class="col text-right" v-if="!disabled">
					<button class="btn" @click="deleteCondition(index)">
						<i class="fa fa-trash text-light"/>
					</button>
				</div>
			</div>
		</div>
		<button class="btn btn-primary" @click="addCondition" v-if="!disabled">Add condition
		</button>
	</div>
</template>

<script>
	import Trigger from "./trigger";

	export default {
		components: {Trigger},
		props: {
			value: {
				type: Array
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		methods: {
			addCondition() {
				this.model.push({
					type: 'extra',
					property: null,
					threshold: 0,
				})
			},
			deleteCondition(index) {
				this.model.splice(index, 1)
			}
		},
		computed: {
			model: {
				get() {
					return this.value
				},
				set(options) {
					console.log('options', options)
					this.$emit('input', options)
				}
			}
		}
	}
</script>
