<template>
	<select :disabled="disabled" v-model="property" class="form-control">
		<option :value="currency" v-for="currency in extraCurrencies" v-text="currency"></option>
	</select>
</template>

<script>
	export default {
		props: {
			disabled: {
				type: Boolean,
				default: false
			},
			value: {
				type: String
			},
			config: {
				type: Object
			}
		},
		mounted() {
			if (this.property === null || !this.extraCurrencies.includes(this.property)) {
				this.property = this.extraCurrencies[0]
			}
		},
		computed: {
			extraCurrencies() {
				return this.config.editorConfig.extraCurrencies
			},
			property: {
				get() {
					return this.value
				},
				set(property) {
					this.$emit('input', property)
				}
			}
		}
	}
</script>
