<template>
	<div class="p-3 d-flex justify-content-start" v-if="trigger">
		<div class="px-3" v-if="types.length > 1">
			<div class="text-nowrap text-small font-weight-bold">
				Type <i class="ml-2 fa fa-keyboard text-light"/>
			</div>
			<div class="d-inline-flex">
					<div class="form-check" v-for="type in types">
						<input :disabled="disabled" class="form-check-input" type="radio" :id="`type-${type.value}`" :value="type.value" v-model="trigger.type">
						<label class="form-check-label" :for="`type-${type.value}`" v-text="type.name"></label>
					</div>
			</div>
		</div>
		<div class="px-3">
			<div class="text-nowrap text-small font-weight-bold">
				Property <i class="ml-2 fa fa-box-open text-light"/>
			</div>
			<div class="d-inline-flex">
				<div :config="config" :disabled="disabled" :is="trigger.type" v-model="trigger.property"></div>
			</div>
		</div>
		<div class="px-3">
			<div class="text-nowrap text-small font-weight-bold">
				Threshold <i class="ml-2 fa fa-long-arrow-alt-up text-light"/>
			</div>
			<div class="d-inline-flex">
				<multiplier :disabled="disabled" v-model="trigger.threshold" :min="0"></multiplier>
			</div>
		</div>
	</div>
</template>

<script>
	import Extra from './trigger/extra'
	import Multiplier from "../prize/multiplier";

	export default {
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		mounted() {
			if (this.trigger.type === null) {
				this.trigger.type = this.types[0].value
			}
		},
		computed: {
			trigger: {
				get() {
					return this.value
				},
				set(trigger) {
					this.$emit("input", trigger)
				}
			},
			types() {
				return [
					{
						name: 'Collectible',
						value: 'extra'
					}
				]
			}
		},
		components: {
			Multiplier,
			Extra
		}
	}
</script>
