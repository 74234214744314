<template>
	<div v-if="options">
		<div class="my-4">
			<h5>Trigger</h5>
			<triggers-list :disabled="disabled" :config="config" v-model="options.trigger"/>
		</div>
		<hr>
		<div class="my-4">

			<div class="row">
				<div class="col">
					<h5>Payouts</h5>
					<div class="text-muted">Freespins will be used for launch baby slot</div>
					<payouts :disabled="disabled" :show-multiplier="false" :show-extra="false" :show-freespins="true" :config="config" v-model="options.payouts"/>
				</div>
				<div class="col">
					<h5>Summary</h5>
					<payouts-summary :config="config" class="bg-light border p-3"
									 :payouts="options.payouts"/>
				</div>
			</div>

		</div>
		<hr>
		<div class="my-4">
			<h5>Baby slot config</h5>
			<slot-config-editor :disabled="disabled" :key="`bonus-${config.Version}`" class="bg-light p-2" identifier="mini-slot-config"
								v-model="options.minislotConfig"/>
		</div>
	</div>
</template>

<script>
	import Trigger from "./trigger";
	import Multiplier from "../prize/multiplier";
	import Payouts from "./payouts";
	import PayoutsSummary from "./payouts-summary";
	import TriggersList from "./triggers-list";
	import SlotConfigEditor from "../index";

	export default {
		components: {
			SlotConfigEditor,
			TriggersList,
			PayoutsSummary,
			Payouts,
			Multiplier,
			Trigger
		},
		props: {
			value: {
				type: Object
			},
			config: {
				type: Object
			},
			disabled: {
				default: false,
				type: Boolean
			}
		},
		mounted() {
			console.log("Mini slot mounted")

			if (this.options === null) {
				this.options = {
					trigger: [],
					payouts: [],
					minislotConfig: {
						reels: [],
						version: '0.0.0.0',
						symbols: [],
						freespinReels: [],
						visibleLines: 3,
						paddingLines: 1,
						coins: [],
						paytable: {
							paylines: [],
							multipliers: {},
							wilds: []
						},
						features: [],
						bonuses: [],
						editorConfig: {
							keepFreespinReelsSynced: false,
							extraCurrencies: [],
							targetRTP: 96
						}
					},
					spins: 0,
					losingPick: false
				}
			}

			if (this.options && this.options.losingPick === undefined) {
				this.$nextTick(() => this.$set(this.options, "losingPick", false))
			}
		},
		methods: {
			addCondition() {
				this.options.trigger.push(null)
			},
			deleteCondition(index) {
				this.options.trigger.splice(index, 1)
			}
		},
		computed: {
			options: {
				get() {
					return this.value
				},
				set(options) {
					console.log('options', options)
					this.$emit('input', options)
				}
			}
		}
	}
</script>
